import React, { useEffect, useState } from "react";
import "../../assets/css/team.css";
import "animate.css";
import placeholder from "../../assets/img/placeholder.png";
import drNamdeen from "../../assets/img/dr-nadeem.png";
import imaan from "../../assets/img/image.png";
import Naiz from "../../assets/img/naiz.jpg";
import naeem from "../../assets/img/naeem.jpg";
import abi from "../../assets/img/abigail-craig.jpg";
import julie from "../../assets/img/julie.png";
import fern from "../../assets/img/fern.webp";
import jamima from "../../assets/img/jamima.webp";
import olivia from "../../assets/img/olivia.webp";
import evie from "../../assets/img/evie-reed.webp";
import nazia from "../../assets/img/nazia.png";

const ProfileSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    setIsVisible(true);
  }, []);

  return (
    <section className="">
      <div className="container mt-5">
        <div>
          <div className="text-center mb-5">
            <h1 className="text-theme">
              {" "}
              Your <span className="text-golden"> Clinical Team</span>
            </h1>
          </div>

          {/* First Row */}
          <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                } mt-4`}
              >
                Dr. Nadeem Sabir
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                BDS (Hons), MFDS RCS Ed <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=244093"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 244093
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                PRINCIPAL & CLINICAL DIRECTOR
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-1s" : ""
                }`}
              >
                Dr Nadeem Sabir graduated from the University of Manchester with
                Honours in 2013 before going on to obtain his Diploma of
                Membership of the Faculty of Dental Surgery (MFDS) from the
                Royal College of Surgeons Edinburgh.
                <br></br>
                <br></br>
                He has trained under some of the most prominent and esteemed
                leaders in Implant Dentistry which has now led him to focus his
                practice solely on dental implants and complex surgical
                dentistry. He is experienced in treating nervous patients with
                intravenous sedation and provides this service for his patients
                when appropriate. Nadeem is involved in training the next
                generation of implant dentists as a mentor for SmileFast
                Implants.
                <br></br>
                <br></br>
                Dr Sabir is a perfectionist and combines true professionalism
                with genuine warmth and informality, ensuring that even the most
                anxious patient is made to feel relaxed and reassured. He is
                renowned for being very approachable and highly empathetic; he
                will explain all treatment modalities and educate all his
                patients before starting treatment.
              </p>

              {/* Fun Fact */}
              <div
                className={`fun-fact animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-2s" : ""
                }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Dr Nadeem is an avid explorer and enjoys spending his free
                  time and hiking and exploring the natural beauty Yorkshire has
                  to offer.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img
                src={drNamdeen}
                alt="Doctor Image"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              />
            </div>
          </div>
          {/* second Row */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-4">
              <img
                src={Naiz}
                alt="Naiz"
                className={`shadow--golden-1 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
            <div className="col-md-8 text-start">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Naiz Khan
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                BDS Brist,United Kingdom, 2005 <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=101469"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 101469
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Cosmetic Dentist
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Dr Naiz Khan qualified from the University of Bristol in 2005
                and has spent the last 15 years treating thousands of patients
                in Gloucester, Cheltenham, Banbury and Worcester.
                <br></br>
                <br></br>
                With a keen interest in orthodontics, Dr Naiz has been providing
                teeth straightening solutions with Invisalign® for nearly a
                decade now to treat a variety of orthodontic concerns including
                teeth that are crooked, crowded or spaced out. Dr Naiz is one of
                the UK’s leading providers of Invisalign® treatments and is part
                of the Apex group of providers which recognises him as one of
                the top 1% of Invisalign® providers in the country.
                <br></br>
                <br></br>
                His aim is to create a long lasting, friendly, and professional
                relationship based on honesty and trust with every patient, so
                that they are at ease when receiving the very best care
                <br></br>
                <br></br>
                Dr Naiz gets his job satisfaction from being able to combine the
                art and science of dentistry to help create smiles, which help
                increase confidence and self-esteem in his patients.
                <br></br>
                <br></br>
                Dr Naiz spends his time outside of surgery with his young
                children and supporting other dentists with their clinical
                skills.
              </p>

              {/* Fun Fact */}
              <div
                className={`fun-fact animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-2s" : ""
                }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Dr. Dr Naiz is a Diamond Apex Invisalign® Provider making him
                  the top 1% of providers worldwide
                </p>
              </div>
            </div>
          </div>
          {/* third Row */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Dr. Imaan Khalid
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                MChD BChD BSc Leeds 2020 <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=290121"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 290121
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Cosmetic Dentist
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Imaan is a dedicated general dentist practicing in West
                Yorkshire. She earned her degree with Distinction from the
                University of Leeds in 2020 and was recognized as a finalist in
                the 2018 Future of Dentistry Awards.
                <br></br> <br></br>
                With a commitment to professional growth and a strong
                affiliation with the British Academy of Cosmetic Dentistry
                (BACD), Imaan has been an active member since 2017. Her
                engagement began as a student representative, and she was later
                invited to join the Young Membership Committee, Communication
                Committee and Trade Committee.
                <br></br> <br></br>
                Imaan's contributions extend beyond her practice. She serves as
                a Committee member and on the Board of Directors for the British
                Association of Private Dentistry.
                <br></br> <br></br>
                Imaan has an unwavering dedication and passion for dentistry and
                drives to continually elevate her skills.
                <br></br> <br></br>
                Imaan is particularly devoted to dental education, aiming to
                inspire and guide younger dentists to attain the exceptional
                standards she holds herself by holding further committee
                positions for Dentinal Tubules.
                <br></br> <br></br>
                Always focused on providing excellence in patient care and
                emphasizing minimally invasive ethical dentistry, Imaan is
                currently pursuing a Postgraduate Diploma in Advanced Aesthetics
                with Elevate Dentistry.
              </p>

              {/* Fun Fact */}
              <div
                className={`fun-fact animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-2s" : ""
                }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Is a member of British Association of Cosmetic Dentists Board
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <img
                src={imaan}
                alt="imaan"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
          </div>
          {/* fifth Row */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-4">
              <img
                src={naeem}
                alt="naeem"
                className={`shadow--golden-1 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
            <div className="col-md-8 text-start">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Dr. Naeem Ashfaq
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                BDS 2019 <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=283591"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 283591
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Cosmetic Dentist
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Dr. Naeem is a compassionate and skilled general dentist
                dedicated to delivering exceptional patient care. A graduate of
                the University of Manchester in 2019, Dr Naeem brings extensive
                experience in oral surgery, having worked in a hospital setting
                where they handled complex soft and hard tissue cases.
                <br />
                <br />
                With a passion for enhancing smiles, Dr. Naeem is also a highly
                trained cosmetic dentist, offering treatments such as composite
                bonding, porcelain veneers, teeth whitening, and Invisalign.
                Beyond aesthetics, Dr. Naeem is experienced in rehabilitating
                patients’ mouths, providing solutions for tooth wear and
                restoring broken-down teeth to their optimal health and
                function.
                <br />
                <br />
                Dr. Naeem is committed to creating a comfortable, relaxing
                environment for patients and is especially skilled in treating
                those who may feel nervous or anxious about dental visits.
                Understanding each patient’s unique needs, Dr. Naeem ensures
                that every treatment is as stress-free and enjoyable as
                possible.
              </p>

              {/* Fun Fact */}
              <div
                className={`fun-fact animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-2s" : ""
                }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Outside of the practice, Dr. Naeem enjoys spending quality
                  time with family, playing football, and staying active at the
                  gym.
                </p>
              </div>
            </div>
          </div>
          {/* jamima starts  */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Jarmima Uddin
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                MChD/ BChD, Bsc (Dist), Bsc <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=277733"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 277733
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Dentist
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Jarmima graduated in 2023 with distinction from a joint Masters
                and Bachelors degree in Dental Surgery. In 2024, she was
                recognised for her work in creating healthy, aesthetic smiles
                and was honoured with several prestigious awards from the
                College of General Dentistry and British Association of Cosmetic
                Dentistry.
                <br />
                <br />
                Jarmima’s dental career began in 2018 when she qualified as a
                dental therapist. She is deeply committed to continuous learning
                and enhancing her skills to deliver outstanding care to her
                patients. Beyond dentistry, Jarmima enjoys travelling, staying
                active, and spending quality time with her loved ones.
              </p>
            </div>

            <div className="col-md-4">
              <img
                src={jamima}
                alt="Imaan"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
          </div>
          {/* jamima ends  */}
          {/* Sixth Row */}
          <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-4">
              <img
                src={abi}
                alt="Doctor Image"
                className={`shadow--golden-1 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              />
            </div>

            <div className="col-md-8 text-start">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                } mt-4`}
              >
                Abigail Turner
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                PGDip Dental Hygiene and Dental Therapy University of Leeds-
                Distinction (2018) <br></br>
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=280994"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 280994
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                Hygienist & Therapist
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-1s" : ""
                }`}
              >
                Abi qualified from the University of Leeds in 2018 with a
                distinction in Dental Hygiene and Therapy. Whilst studying she
                received the GSK clinical competence prize, the BSDHT Research
                Prize as well as the BADT paediatric dentistry prize.
                <br></br>
                <br></br>
                Abi has worked in private practice as well as the Leeds Dental
                Institute where she took a special interest in investigating the
                relationship between oral health and general health,
                specifically for patients with cancers and auto-immune
                conditions.
                <br></br>
                <br></br>
                Abi understands that for many, coming to see the hygienist can
                be a daunting experience.  She is a firm believer in taking a
                gentle, non-judgmental approach to hygiene treatment, and is
                passionate about the benefits good oral health can have on your
                general health.
              </p>

              {/* Fun Fact */}
              <div
                className={`fun-fact animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-2s" : ""
                }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Abi grew up in Ilkley and loves exploring the outdoors with
                  her energetic Labrador and playing tennis.
                </p>
              </div>
            </div>
          </div>
          {/* Seventh Row */}
          {/* <h2 className="text-center">Your Patient Care Team</h2> */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Julie Bastow
              </h2>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Practice Manager
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Julie has worked in a private and NHS dental practice
                specialising in orthodontics since 2013, and became a CQC
                Registered Manager in 2018 running two very successful local
                practices. Julie’s previous experience includes working as a
                P.A. for several companies based in the UK and Ireland.
                <br />
                <br />
                Julie will work alongside the team to develop business
                strategies and patient services, and to provide them with the
                necessary support. She will also oversea the daily practice
                operations to ensure that the practice runs smoothly and safely
                and provides our patients with an excellent experience.
                <br />
                <br />
                When Julie is not at work she enjoys spending time with her
                family, walking her dog and expressing her artistic abilities.
              </p>
            </div>

            <div className="col-md-4">
              <img
                src={julie}
                alt="Imaan"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
          </div>
          {/* Eighth Row */}
          <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-4">
              <img
                src={olivia}
                alt="Doctor Image"
                className={`shadow--golden-1 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              />
            </div>

            <div className="col-md-8 text-start">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                } mt-4`}
              >
                Olivia Leggoe
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=307063"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No - 307063
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                Treatment Co-ordinator
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-1s" : ""
                }`}
              >
                Olivia is an experienced dental nurse dedicated to exceptional
                patient care since 2020. Skilled in both NHS and private
                practice, ensuring a comfortable experience for all. Olivia is
                driven by the pursuit of challenges and embraces every
                opportunity for growth.
                <br />
                <br />
                In her spare time she is a lover of scenic walks and an advocate
                for a healthy lifestyle. Thriving in the gym and enjoying the
                beauty of the outdoors.
              </p>
            </div>
          </div>
          {/* nineth Row */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Evie Reed
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=313548"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 313548
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Dental Nurse
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                I began my nursing career during the Covid pandemic, a
                challenging yet pivotal time that shaped my dedication to
                patient care. Since then, I have trained extensively with some
                of the best clinicians in a wide range of dental specialties,
                including orthodontics, paediatrics, implants, sedation, and
                advanced oral surgery. As a fully sedation-trained nurse, I take
                pride in supporting patients through complex treatments while
                ensuring they feel calm and at ease throughout the process.
                <br />
                <br />
                What truly sets me apart is my passion for working with nervous
                patients. Helping them overcome their fears and watching their
                confidence grow with each visit is one of the most rewarding
                aspects of my role. I strive to create a safe, supportive
                environment where patients feel valued and empowered, ensuring
                they leave each appointment with a positive experience.
                <br />
                <br />
                Outside of work, I enjoy spending time outdoors with my horses,
                I also love traveling and recently returned from Thailand, where
                I embraced the vibrant culture, stunning views, and delicious
                cuisine.
              </p>
            </div>

            <div className="col-md-4">
              <img
                src={evie}
                alt="evie"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
          </div>
          {/* tenth Row */}
          <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-4">
              <img
                src={fern}
                alt="Doctor Image"
                className={`shadow--golden-1 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              />
            </div>

            <div className="col-md-8 text-start">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                } mt-4`}
              >
                Fern Bastow
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=293538"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC No 293538
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              >
                Nurse
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInRight animate__delay-1s" : ""
                }`}
              >
                Fern is an experienced and dedicated dental nurse with a career
                spanning since 2018. With a passion for patient care, she has
                honed her skills in both private and NHS practice, gaining
                expertise in general dentistry as well as orthodontics. Her
                comprehensive training includes advanced qualifications in
                radiographs and impression taking, further enhancing her ability
                to support dental professionals and ensure high-quality care.
                <br></br>
                <br></br>
                Outside of the clinic, Fern enjoys staying active by walking her
                dog, going to the gym, and experimenting with new recipes in the
                kitchen, particularly when it comes to baking. Her balanced
                approach to life, combining professional development with
                personal passions, makes her a well-rounded and motivated member
                of any dental team.
              </p>

              {/* Fun Fact */}
              {/* <div
                className={`fun-fact animate__animated ${isVisible ? "animate__fadeInRight animate__delay-2s" : ""
                  }`}
              >
                <h6 className="fw-bold">Fun Fact</h6>
                <p>
                  Fern grew up in Ilkley and loves exploring the outdoors with
                  her energetic Labrador and playing tennis.
                </p>
              </div> */}
            </div>
          </div>
          {/* nineth Row */}
          <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
            <div className="col-md-8 text-end">
              <h2
                className={`fw-bold animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Nazia Hussain
              </h2>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=142740"
                  target="_blank"
                  className="text-black text-decoration-none"
                >
                  GDC number 142740
                </a>
              </p>
              <h5
                className={`text-golden text-uppercase animate__animated ${
                  isVisible ? "animate__fadeInLeft" : ""
                }`}
              >
                Dental Nurse
              </h5>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__fadeInLeft animate__delay-1s" : ""
                }`}
              >
                Nazia is an experienced dental nurse who has developed a deep
                understanding of her profession. She possesses a wealth of
                knowledge and expertise that allows her to excel in her role and
                provide exceptional patient care. Nazia's abilities and
                proficiency in dentistry are a testament to her dedication and
                commitment to her profession. She is a passionate dental nurse
                who has a keen interest in learning new things and gaining more
                experience in different procedures. Her dedication to her work
                has helped her gain a reputation as a highly reliable and
                trustworthy professional.
                <br />
                <br />
                Nazia is a true people person and believes in being positive at
                all times. She understands the importance of making patients
                feel comfortable and at ease during their dental appointments.
                Her compassionate and caring nature has earned her the respect
                and appreciation of her patients. Nazia chose this career
                because of her passion for dentistry, and she is committed to
                providing the highest quality of care to all her patients.
                <br />
                <br />
                Aside from her love for dentistry, Nazia also enjoys the company
                of family, friends, and the people she works with. She finds it
                fulfilling to meet new patients and help them achieve optimal
                oral health. Nazia is a dedicated team player, always willing to
                lend a helping hand to her colleagues. Her enthusiasm and
                positivity make her a pleasure to work with, and her commitment
                to excellence in patient care makes her an invaluable member of
                the Regent Dental Practice team.
                <br />
                <br />
                Outside of work, I love spending time with my three children and
                husband, as well as my parents and siblings, making memories.
              </p>
            </div>

            <div className="col-md-4">
              <img
                src={nazia}
                alt="nazia"
                className={`shadow-golden-2 img-fluid profile-image animate__animated ${
                  isVisible ? "animate__fadeInRight" : ""
                }`}
              />
            </div>
          </div>
        </div>

        <div>
          {/* <div className='text-center mb-5'>
                        <h1 className='text-theme'> Your <span className='text-golden'> Support Team</span></h1>
                    </div>
                    
                    
                    <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                        
                        <div className="col-md-4">
                            <img 
                                src={placeholder}
                                alt="Doctor Image" 
                                className={`shadow--golden-1 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            />
                        </div>
                        <div className="col-md-8 text-start">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                                Julie Bastow
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            BSc (Sheffield 2008) <br></br> 
                            <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=153699" target='_blank' className='text-black text-decoration-none'>GDC No 153699</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            Practice Manager
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non venenatis arcu. In vestibulum ante in nisl volutpat tristique. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla egestas ex augue, vulputate pretium tortor sagittis sed. Phasellus dolor dui, posuere pretium scelerisque at, sagittis a justo. Phasellus ultrices tincidunt orci nec ornare. Curabitur quis erat elementum nisi facilisis malesuada ut eget erat. Sed blandit lobortis justo et convallis.
                                <br></br>
                                <br></br>
                                Vestibulum non elementum mi, vel congue magna. Ut in felis eget ligula consequat pretium. Donec at nisl condimentum, varius arcu at, blandit erat. Proin pharetra quam neque, et cursus turpis vestibulum sit amet. Nulla turpis metus, lacinia at sapien tempor, accumsan congue urna. Morbi feugiat fermentum massa, vitae porttitor mauris vehicula fermentum. Pellentesque in ex nisi. Nullam magna libero, ullamcorper eget blandit a, finibus sed arcu. In iaculis ex eget auctor placerat. Vivamus blandit, ex nec gravida faucibus, quam risus sodales mi, quis cursus mi quam et massa. Suspendisse convallis quis metus sit amet vestibulum. Maecenas pulvinar, lorem ut sodales condimentum, sem dui consectetur sem, vitae sodales ante nisi non velit. Nullam et arcu eu odio finibus feugiat ut sed arcu. Donec bibendum est et porttitor ornare.
                                </p> 
                    
                            
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Dr. Desai was named one of “Austin’s Best Dressed” by CultureMap!</p>
                            </div>
                        </div>
                        
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default ProfileSection;
