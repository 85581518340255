import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/invisalign/hero";
import WhatAndWhy from "../components/invisalign/what-and-why";
import Process from "../components/invisalign/process";
import AfterCare from "../components/invisalign/aftercare";
import Benifits from "../components/invisalign/benefits";
import CTA from "../components/invisalign/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>
          Invisalign&#174; in Ilkley, West Yorkshire | Regent Dental
        </title>
        <meta
          name="description"
          content="Discover the clear alternative to braces at Regent Dental. Our Invisalign® treatment offers a discreet and effective way to achieve your perfect smile."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <Benifits />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
