import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/general-dentistry/hero";
import WhatAndWhy from "../components/general-dentistry/what-and-why";
import Process from "../components/general-dentistry/process";
import AfterCare from "../components/general-dentistry/aftercare";
import CTA from "../components/general-dentistry/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>
          General Dentistry in Ilkley, West Yorkshire | Regent Dental{" "}
        </title>
        <meta
          name="description"
          content="Discover comprehensive dental care at Regent Dental. From routine check-ups to fillings, we ensure your smile stays bright and your teeth healthy."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
