import React, { useEffect, useRef, useState } from "react";
import "animate.css"; // Ensure Animate.css is imported

const PaymentOptions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Set to true when the section is visible
      } else {
        setIsVisible(false); // Set to false when the section is not visible
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className="container-fluid"
      style={{ backgroundColor: "#F7F7F7" }}
    >
      <div
        className={`row mb-5 align-items-center justify-content-center min-vh-50 ${
          isVisible
            ? "animate__animated animate__zoomInDown"
            : "animate__animated animate__zoomOutDown"
        }`}
      >
        <div className="col-12 text-center mt-5 mb-5 w-100 w-lg-50">
          <div>
            <p className="fw-bold">Dental Care for Every Wallet</p>
            <h1 className="text-theme" style={{ fontWeight: 600 }}>
              Flexible Payment Options
            </h1>
            <p>
              We offer flexible payment options that fit your budget, ensuring
              you receive exceptional care without compromise.
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="container">
            <div className="row">
              {/* Card 1 */}
              <div
                className={`col-lg-4 mb-5 ${
                  isVisible
                    ? "animate__animated animate__zoomInDown"
                    : "animate__animated animate__zoomOutDown"
                }`}
              >
                <div
                  className="card border-0 shadow-golden-3 d-flex flex-column h-100"
                  style={{ borderRadius: "32px 0px 0px 0px" }}
                >
                  <div className="card-body p-5 d-flex flex-column flex-grow-1">
                    <h5>Pay As You Go</h5>
                    <p>
                      We have worked hard to make sure our prices are as
                      competitive as they can be without compromising on the
                      service, equipment and materials we use and provide with
                      every interaction.
                    </p>
                    <div className="mt-auto">
                      <a
                        href="/dental-fees"
                        className="text-theme fw-200 text-decoration-none"
                      >
                        View our prices{" "}
                        <i className="fa fa-arrow-right ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div
                className={`col-lg-4 mb-5 ${
                  isVisible
                    ? "animate__animated animate__zoomInDown"
                    : "animate__animated animate__zoomOutDown"
                }`}
              >
                <div
                  className="card border-0 shadow-golden-3 d-flex flex-column h-100"
                  style={{ borderRadius: "32px 0px 0px 0px" }}
                >
                  <div className="card-body p-5 d-flex flex-column flex-grow-1">
                    <h5>Dental Plans</h5>
                    <p>
                      Sometimes it helps to spread the cost of your check-ups so
                      we’ve teamed up with country’s leading plan provider,
                      Denplan. With Denplan you can choose the plan that suits
                      you with prices starting from £11.95.
                    </p>
                    <div className="mt-auto">
                      <a
                        href="/dental-plans"
                        className="text-theme fw-500 text-decoration-none"
                      >
                        Find the right plan for you{" "}
                        <i className="fa fa-arrow-right ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 3 */}
              <div
                className={`col-lg-4 mb-5 ${
                  isVisible
                    ? "animate__animated animate__zoomInDown"
                    : "animate__animated animate__zoomOutDown"
                }`}
              >
                <div
                  className="card border-0 shadow-golden-3 d-flex flex-column h-100"
                  style={{ borderRadius: "32px 0px 0px 0px" }}
                >
                  <div className="card-body p-5 d-flex flex-column flex-grow-1">
                    <h5>Treatment Finance</h5>
                    <p>
                      We have teamed up Tabeo to provide clear and transparent
                      finance offerings for all our patients. Tabeo will check
                      your eligibility and provide finance options that are
                      appropriate for you, including up to 12 months interest
                      free options.
                    </p>
                    <div className="mt-auto">
                      <a
                        href="https://lead.tabeo.co.uk/regent-dental/finance"
                        className="text-theme fw-500 text-decoration-none"
                        target="_blank"
                      >
                        Check your eligibility{" "}
                        <i className="fa fa-arrow-right ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-12 d-flex justify-content-center mt-2 mb-5 align-items-center ${
            isVisible
              ? "animate__animated animate__zoomInDown"
              : "animate__animated animate__zoomOutDown"
          }`}
        >
          <a href="/contact-us" className="btn btn-theme me-4">
            Book an Appointment
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
