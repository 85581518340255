import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/img/logo.png";
import "font-awesome/css/font-awesome.min.css";
import "animate.css"; // Ensure Animate.css is imported
import "../../assets/css/floating-btn.css";
const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Set to true when the section is visible
      } else {
        setIsVisible(false); // Set to false when the section is not visible
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  // Get the current date
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return (
    <>
      <div className="row bg-theme" ref={ref}>
        <div className="col-12 container">
          <div className="row px-5 py-3 align-items-center">
            <div className="col-md-6 p-3">
              <img
                src={Logo}
                alt=""
                height="60"
                className={`animate__animated ${
                  isVisible ? "animate__bounceIn" : "animate__bounceOut"
                }`}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end px-5">
              <p
                className={`mb-0 text-light animate__animated ${
                  isVisible ? "animate__bounceIn" : "animate__bounceOut"
                }`}
              >
                <span className="text-golden fs-20"> Follow Us </span>{" "}
                &nbsp;&nbsp;
                <a
                  href="https://www.facebook.com/profile.php?id=61564954080373"
                  target="_blank"
                  className="text-white text-decoration-none"
                >
                  <i className="fa fa-facebook me-2" aria-hidden="true"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://www.instagram.com/regentdental_ilkley/"
                  target="_blank"
                  className="text-white text-decoration-none"
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </p>
            </div>
            <div className="col-12 border mt-3"></div>
            <div className="col-12">
              <div className="text-center text-light mt-3">
                <p
                  className={`mb-0 fs-11 footer-bottom animate__animated ${
                    isVisible ? "animate__bounceIn" : "animate__bounceOut"
                  }`}
                >
                  © {new Date().getFullYear()} Regent Dental. All Rights
                  Reserved. <br />
                  <a
                    href="/privacy-policy"
                    className="text-white text-decoration-none"
                  >
                    {" "}
                    Privacy & Cookie Policy{" "}
                  </a>{" "}
                  |{" "}
                  <a
                    href="/terms-of-use"
                    className="text-white text-decoration-none"
                  >
                    {" "}
                    Terms of Use
                  </a>{" "}
                  |{" "}
                  <a
                    href="https://www.cqc.org.uk/location/1-20243138251"
                    target="_blank"
                    className="text-white text-decoration-none"
                  >
                    CQC
                  </a>{" "}
                  |{" "}
                  <a
                    href="https://www.gdc-uk.org/"
                    target="_blank"
                    className="text-white text-decoration-none"
                  >
                    GDC
                  </a>{" "}
                  |{" "}
                  <a
                    href="/complain-policy"
                    target="_blank"
                    className="text-white text-decoration-none"
                  >
                    Complaints Policy
                  </a>
                  <br />
                  designed and maintained by{" "}
                  <a
                    className="text-light text-decoration-none"
                    href="https://www.smile-mc.co.uk/"
                    target="_blank"
                  >
                    {" "}
                    smile marketing & consultancy{" "}
                  </a>{" "}
                  <br />
                  website last updated on {formattedDate} <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        className="fixed-button"
        href="https://onlineappointment.carestack.uk/?dn=regent"
        rel="noreferrer"
        target="_blank"
      >
        <div class="vertical-text">Book Online</div>
      </a>
      <a className="fixed-button2" href="tel:01943661331">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 24 24"
            id="call"
          >
            <path d="M17.682 24.006a5.159 5.159 0 0 1-1.632-.266 25.11 25.11 0 0 1-9.757-6.033A25.094 25.094 0 0 1 .261 7.951a5.107 5.107 0 0 1 1.528-5.517l1.9-1.628a3.352 3.352 0 0 1 4.8.452L10.9 4.275a3.009 3.009 0 0 1 .211 3.449l-1.1 1.785a1 1 0 0 0 .143 1.232l3.1 3.1a1 1 0 0 0 1.231.144l1.786-1.1a3.005 3.005 0 0 1 3.449.211l3.017 2.414a3.353 3.353 0 0 1 .452 4.8l-1.628 1.9a5.105 5.105 0 0 1-3.879 1.796ZM5.869 2a1.346 1.346 0 0 0-.878.325L3.09 3.953a3.116 3.116 0 0 0-.933 3.365 23.1 23.1 0 0 0 5.55 8.975 23.1 23.1 0 0 0 8.976 5.549 3.116 3.116 0 0 0 3.365-.932l1.627-1.9a1.351 1.351 0 0 0-.183-1.935l-3.017-2.414a1 1 0 0 0-1.15-.07l-1.785 1.1a2.987 2.987 0 0 1-3.695-.432l-3.1-3.1a2.99 2.99 0 0 1-.432-3.7l1.1-1.785a1 1 0 0 0-.07-1.15L6.925 2.508A1.345 1.345 0 0 0 5.869 2Z"></path>
          </svg>
        </span>
        01943 661331
      </a>
    </>
  );
};

export default Footer;
