import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Breadcrumb from "../components/universal/breadcrumb";
import ReferralForm from "../components/dental-implant-referral/referral-form";
import Footer from "../components/universal/footer";

function Index() {
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "Referrals", href: "#" },
    { label: "Dental Implant", isActive: true },
  ];

  return (
    <div>
      <Helmet>
        <title>Dental Implant Referrals | Regent Dental</title>
        <meta name="description" content="Dental implant referral form" />
      </Helmet>
      <Navbar />
      <div className="container">
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </div>
      <ReferralForm />
      <Footer />
    </div>
  );
}

export default Index;
