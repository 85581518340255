import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/teeth-whitening/hero";
import WhatAndWhy from "../components/teeth-whitening/what-and-why";
import Process from "../components/teeth-whitening/process";
import AfterCare from "../components/teeth-whitening/aftercare";
import CTA from "../components/teeth-whitening/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Teeth Whitening in Ilkley, West Yorkshire | Regent Dental</title>
        <meta
          name="description"
          content="Transform your smile with Regent Dental's tailored teeth whitening solutions. Unlock your confidence and enjoy the beauty of a brighter, healthier smile."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
