import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/3.png"; // Adjust the path as needed

const Navbar = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container-fluid px-4">
          <Link
            className="navbar-brand animate__animated animate__zoomIn"
            to="/"
          >
            <img src={logo} alt="Logo" height="60" />
          </Link>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            aria-controls="collapsibleNavId"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon fs-12"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavId">
            <ul className="navbar-nav ms-auto me-auto mt-2 mt-lg-0">
              <li className="nav-item animate__animated animate__zoomIn dropdown">
                <Link
                  className="nav-link px-3 dropdown-toggle"
                  to="#"
                  id="dropdownId1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About Us
                </Link>
                <div
                  className="dropdown-menu  border-0 shadow"
                  aria-labelledby="dropdownId1"
                  style={{ minWidth: "250px" }}
                >
                  <Link className="dropdown-item p-lg-3 " to="/team">
                    Meet The Team
                  </Link>
                  <Link className="dropdown-item p-lg-3 " to="/our-technology">
                    Our Technology
                  </Link>
                </div>
              </li>

              <li className="nav-item animate__animated animate__zoomIn dropdown">
                <Link
                  className="nav-link px-3 dropdown-toggle"
                  to="#"
                  id="dropdownId1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Treatments
                </Link>
                <div
                  className="dropdown-menu  border-0 shadow"
                  aria-labelledby="dropdownId1"
                  style={{ minWidth: "250px" }}
                >
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/general-dentistry"
                  >
                    General Dentistry
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/invisalign"
                  >
                    Invisalign<sup>&#174;</sup>
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/niTime-aligners"
                  >
                    NiTime Aligners
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/dental-implants"
                  >
                    Dental Implants
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/all-on-4-dental-implants"
                  >
                    All on 4 Dental Implants
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/smile-fast-composite-bonding"
                  >
                    Composite Bonding
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/porcelain-veneers"
                  >
                    Porcelain Veneers
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/teeth-whitening"
                  >
                    Teeth Whitening
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/restorative-dentistry"
                  >
                    Restorative Dentistry
                  </Link>
                  <Link className="dropdown-item p-lg-3" to="/root-canal">
                    Root Canal Treatment
                  </Link>
                </div>
              </li>

              <li className="nav-item animate__animated animate__zoomIn dropdown">
                <Link className="nav-link px-3" to="/patient-stories">
                  Patient Stories
                </Link>
              </li>

              <li className="nav-item animate__animated animate__zoomIn dropdown">
                <Link
                  className="nav-link px-3 dropdown-toggle"
                  to="#"
                  id="dropdownId1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Fees and Finance
                </Link>
                <div
                  className="dropdown-menu  border-0 shadow"
                  aria-labelledby="dropdownId1"
                  style={{ width: "250px" }}
                >
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/dental-fees"
                  >
                    Fees
                  </Link>
                  <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="/dental-plans"
                  >
                    Dental Plans
                  </Link>
                  {/* <Link
                    className="dropdown-item p-lg-3  border-bottom"
                    to="https://lead.tabeo.co.uk/regent-dental/finance"
                    target="_blank"
                  >
                    Finance Options
                  </Link> */}
                </div>
              </li>

              <li className="nav-item animate__animated animate__zoomIn dropdown"></li>
            </ul>
            <div className="d-flex my-2 my-lg-0 me-1">
              <Link
                to="/contact-us"
                className="fs-14 btn btn-theme-nav animate__animated animate__zoomIn"
              >
                Contact Us
              </Link>
            </div>

            <div className="d-flex my-2 my-lg-0 dropdown">
              <Link
                className="btn-golden-nav nav-link px-3 dropdown-toggle animate__animated animate__zoomIn"
                to="#"
                id="dropdownId1"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Referrals
              </Link>
              <div
                className="dropdown-menu border-0 shadow rounded"
                aria-labelledby="dropdownId1"
                style={{ minWidth: "200px", marginTop: "3.0rem" }} // Adjust margin to position the dropdown correctly
              >
                <Link
                  className="dropdown-item p-3 border-bottom"
                  to="/referral/dental-implant"
                >
                  Dental Implant
                </Link>
                <Link
                  className="dropdown-item p-3 border-bottom"
                  to="/referral/extractions"
                >
                  Extractions
                </Link>
                <Link className="dropdown-item p-3" to="/referral/cbct">
                  CBCT
                </Link>
              </div>
            </div>
            <div className="d-flex d-lg-none my-2 my-lg-0 me-1">
              <a
                className="fs-14 btn btn-theme-nav animate__animated animate__zoomIn"
                href="tel:01943661331"
                style={{ alignItems: "center", gap: "5px" }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 24 24"
                    id="call"
                  >
                    <path d="M17.682 24.006a5.159 5.159 0 0 1-1.632-.266 25.11 25.11 0 0 1-9.757-6.033A25.094 25.094 0 0 1 .261 7.951a5.107 5.107 0 0 1 1.528-5.517l1.9-1.628a3.352 3.352 0 0 1 4.8.452L10.9 4.275a3.009 3.009 0 0 1 .211 3.449l-1.1 1.785a1 1 0 0 0 .143 1.232l3.1 3.1a1 1 0 0 0 1.231.144l1.786-1.1a3.005 3.005 0 0 1 3.449.211l3.017 2.414a3.353 3.353 0 0 1 .452 4.8l-1.628 1.9a5.105 5.105 0 0 1-3.879 1.796ZM5.869 2a1.346 1.346 0 0 0-.878.325L3.09 3.953a3.116 3.116 0 0 0-.933 3.365 23.1 23.1 0 0 0 5.55 8.975 23.1 23.1 0 0 0 8.976 5.549 3.116 3.116 0 0 0 3.365-.932l1.627-1.9a1.351 1.351 0 0 0-.183-1.935l-3.017-2.414a1 1 0 0 0-1.15-.07l-1.785 1.1a2.987 2.987 0 0 1-3.695-.432l-3.1-3.1a2.99 2.99 0 0 1-.432-3.7l1.1-1.785a1 1 0 0 0-.07-1.15L6.925 2.508A1.345 1.345 0 0 0 5.869 2Z"></path>
                  </svg>
                </span>
                01943 661331
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
