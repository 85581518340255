import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/team/teampageHero";
import Team from "../components/team/team";
import Partners from "../components/universal/partners";
import CTA from "../components/universal/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Meet the Team | Regent Dental</title>
        <meta
          name="description"
          content="Meet our team of experienced private dentists in Ilkley, West Yorkshire, focused on offering top-quality dental care in a friendly and welcoming environment."
        />
      </Helmet>
      <Navbar />
      <Header />
      <Team />
      <Partners />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
