import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/complain-policy/hero";
import PP from "../components/complain-policy/content";
import CTA from "../components/porcelain-veneers/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Complaints Policy | Regent Dental</title>
        <meta
          name="description"
          content="Our complaints policy ensures your concerns are addressed promptly and professionally."
        />
      </Helmet>
      <Navbar />
      <Header />
      <PP />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
