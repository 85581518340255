import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/root-canal/hero";
import WhatAndWhy from "../components/root-canal/what-and-why";
import Process from "../components/root-canal/process";
import AfterCare from "../components/root-canal/aftercare";
import CTA from "../components/root-canal/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>
          Root Canal Dentist in Ilkley, West Yorkshire | Regent Dental
        </title>
        <meta
          name="description"
          content="Experience stress-free root canal treatments at Regent Dental. Our advanced technology and skilled dentists make the process as easy as getting a filling."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
