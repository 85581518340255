import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/contact-us/hero-copy";
import ContactForm from "../components/contact-us/contact-form-copy";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Contact Us | Regent Dental</title>
        <meta
          name="description"
          content="Begin your smile journey with Regent Dental Care in Ilkley, West Yorkshire. Contact us now to book your appointment."
        />
      </Helmet>
      <Navbar />
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Index;
