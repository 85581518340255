import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/terms-of-use/hero";
import PP from "../components/terms-of-use/content";
import CTA from "../components/porcelain-veneers/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Website Terms of Use | Regent Dental</title>
        <meta
          name="description"
          content="Ensure a clear understanding of our policies and your rights as a patient."
        />
      </Helmet>
      <Navbar />
      <Header />
      <PP />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
