import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/restorative-dentistry/hero";
import WhatAndWhy from "../components/restorative-dentistry/what-and-why";
import Process from "../components/restorative-dentistry/process";
import AfterCare from "../components/restorative-dentistry/aftercare";
import CTA from "../components/restorative-dentistry/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>
          Restorative Dentistry in Ilkley, West Yorkshire | Regent Dental
        </title>
        <meta
          name="description"
          content="Regent Dental focuses on restorative dentistry with the latest technology, helping you restore your teeth's function and aesthetics for a healthier, more confident smile."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
