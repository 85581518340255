import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/fee/feepageHero";
import FeeTables from "../components/fee/feeTables";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Dental Prices in Ilkley, West Yorkshire | Regent Dental</title>
        <meta
          name="description"
          content="Transparent dental fees and prices at Regent Dental Care in Ilkley, West Yorkshire. Get the care you need without hidden costs."
        />
      </Helmet>
      <Navbar />
      <Header />
      <FeeTables />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
