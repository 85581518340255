import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/niTime-aligner/hero";
import WhatAndWhy from "../components/niTime-aligner/what-and-why";
import Process from "../components/niTime-aligner/process";
import AfterCare from "../components/niTime-aligner/aftercare";
import CTA from "../components/niTime-aligner/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>NiTime Aligners in Ilkley, West Yorkshire | Regent Dental</title>
        <meta
          name="description"
          content="Discover the convenience of NiTime Aligners at Regent Dental. Wear them just 12 hours a day and enjoy orthodontic treatment without daily disruptions."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
