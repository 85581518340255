import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/patient-stories/hero";
import About from "../components/patient-stories/about";
import Gallery from "../components/patient-stories/gallery";
import Testimonials from "../components/universal/testimonials";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Patient Success Stories Before & After | Regent Dental</title>
        <meta
          name="description"
          content="At Regent Dental, our dedicated team crafts success stories daily. Explore some of our favorite transformations and see how we can improve your smile."
        />
      </Helmet>
      <Navbar />
      <Header />
      <About />
      <Gallery />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
