import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/dental-plans/hero";
import What from "../components/dental-plans/what";
import Why from "../components/dental-plans/why";
import Plan from "../components/dental-plans/pricing-plans";
import CTA from "../components/universal/cta";
import Contact from "../components/universal/contact";
import Partners from "../components/universal/partners";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Dental Payment Plans | Denplan | Regent Dental</title>
        <meta
          name="description"
          content="Join Denplan Essentials at Regent Dental Care in Ilkley for affordable access to private dental services. Monthly fees cover check-ups, hygiene visits, and x-rays."
        />
      </Helmet>
      <Navbar />
      <Header />
      <What />
      <Why />
      <Plan />
      <CTA />
      <Partners />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
